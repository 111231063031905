import React from "react";
import 'moment/locale/fr';
import {Checkbox, Input, InputNumber, Select, Form, DatePicker, Radio, Space, AutoComplete, Switch, Upload, Button, Popconfirm} from "antd";

import { UploadOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/fr_FR';

const { Option } = Select;
const { Dragger } = Upload;

export const FormInput = ({name, rules=null, label = null, placeholder, suffix = null, type = 'text', initialValue, preserve = false, alignLabel = 'left', disabled = false, size = null, onChange = null, addonAfter = null, maxLength = null, ...rest}) => {

  let input;
  let itemStyle;

  switch (type) {
    case 'textArea':
      input = <Input.TextArea  placeholder={placeholder} maxLength={maxLength} style={rest.style} disabled={disabled} onChange={onChange}/>;
      break;
    case 'currency':
      input = <InputNumber
        placeholder={placeholder}
        style={{...rest.style, borderRight: 'none', borderRadius: '4px 0 0 4px', width: '100%'}}
        formatter={value => value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
        decimalSeparator={','}
        precision={2}
        disabled={disabled}
        onChange={onChange}
        controls={false}
      />;
      itemStyle = {
        display: 'inline-block',
        width: '80%'
      };
      break;
    case 'number':
      input = <InputNumber
        placeholder={placeholder}
        style={{...rest.style}}
        size={size}
        formatter={value => value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ")}
        decimalSeparator={','}
        precision={2}
        disabled={disabled}
        onChange={onChange}
        controls={false}
        addonAfter={addonAfter}
      />;
      break;
    case 'datePicker':
      input = <DatePicker placeholder={placeholder} style={{...rest.style, width: '100%'}} format={'DD/MM/YYYY'} locale={locale} disabled={disabled} onChange={onChange} />;
      break;
    default:
      input = <Input type={type} maxLength={maxLength} placeholder={placeholder} style={rest.style} suffix={suffix} size={size} disabled={disabled} onChange={onChange} />;
      break;
  }

  return (
    <>
      {
        label ? (
          <div style={{width: '100%', textAlign: alignLabel}}><label>{label} {(rules && rules[0] && (rules[0].required === true || rules[0].warningOnly)) ? (<span style={{color: 'red'}}>*</span>) : ''}</label></div>
        ): ''
      }
      <Form.Item
        name={name}
        rules={(rules && rules[0]) ? rules : null}
        preserve={preserve}
        shouldUpdate={true}
        initialValue={initialValue}
        style={itemStyle}
      >
        {
          input
        }
      </Form.Item>
      {
        type === 'currency' && (
          <div style={{
            background: 'white',
            border: 'solid lightgrey 1px',
            borderLeft: 'none',
            padding: 4,
            height: 32,
            width: '20%',
            display: 'inline-block',
            borderRadius: '0 4px 4px 0',
            color: '#afaeae',
            textAlign: 'center',
            marginTop: 5
          }}>€</div>
        )
      }
    </>
  )
};

export const FormSelect = ({name, rules, label = null, placeholder, options, initialValue, preserve = false, onChange = null, showSearch=false,defaultValue=null, ...rest}) => (
  <>
    {
      label ? (
        <label>{label} {(rules && rules[0] && rules[0].required === true) ? (<span style={{color: 'red'}}>*</span>) : ''}</label>
      ): ''
    }
    <Form.Item
      name={name}
      rules={(rules && rules[0] && rules[0].warningOnly) ? null : rules}
      initialValue={initialValue}
      preserve={preserve}
      style={rest.style}
    >
      <Select
        defaultValue={defaultValue}
        showSearch={showSearch} 
        placeholder={placeholder} 
        onChange={onChange}
        optionFilterProp="children"
        filterOption={ showSearch ?
          (input, option) =>
            (option?.children ?? '').toLowerCase().includes(input.toLowerCase()) : undefined
        }
        filterSort={ showSearch ? 
          (optionA, optionB) =>
            (optionA?.children ?? '').toLowerCase().localeCompare((optionB?.children ?? '').toLowerCase()) : undefined
        }
      >
        {
          options.map(option => (
            <Option key={option.value} value={option.value}>{option.text}</Option>
          ))
        }
      </Select>
    </Form.Item>
  </>
);

export const FormAutocomplete = ({name, rules, label = null, placeholder, options, initialValue, preserve = false, disabled, onBlur, onSelect, onChange, normalize, maxLength = null , ...rest}) => (
  <>
    {
      label ? (
        <label>{label} {(rules && rules[0] && rules[0].required === true) ? (<span style={{color: 'red'}}>*</span>) : ''}</label>
      ): ''
    }
    <Form.Item name={name} rules={rules} initialValue={initialValue} preserve={preserve} normalize={normalize}>
      <AutoComplete
        disabled={disabled}
        onBlur={onBlur}
        onSelect={onSelect}
        onChange={onChange}
        style={rest.style}
        placeholder={placeholder}
        options={options}
        maxLength={maxLength}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  </>
);

export const FormRadio= ({onClick, name, rules, options, initialValue, layout, id, preserve = false, disabled = false,defaultValue=null, ...rest}) => (
  <>
    <Form.Item name={name} rules={rules} initialValue={initialValue} preserve={preserve}>
        <Radio.Group defaultValue={defaultValue}>
          <Space direction={layout}>
            {
              options && options.map((option, idx) => {
                return (
                  <Radio onClick={onClick} key={idx} id={id} value={option.value} disabled={option.disabled ? option.disabled : disabled} style={{margin: '10px 0px', textAlign: 'center', ...rest.style}}>{option.label}</Radio>
                )
              })
            }
          </Space>
        </Radio.Group>
    </Form.Item>
  </>
);

export const FormCheckbox = ({name, rules, label, id, onChange, initialValue, preserve = false, disabled = false, ...rest}) => (
  <Form.Item name={name} rules={rules} valuePropName="checked" initialValue={initialValue} preserve={preserve}>
    <Checkbox id={id} onChange={onChange} defaultChecked={initialValue} disabled={disabled} >
      <label htmlFor={id}>{label}</label>
    </Checkbox>
  </Form.Item>
);

export const FormSwitch = ({name, rules, label, id, onChange, initialValue, preserve = false, ...rest}) => (
  <Form.Item name={name} rules={rules} valuePropName="checked" initialValue={initialValue} preserve={preserve}>
    <Switch id={id} onChange={onChange} defaultChecked={initialValue} /> <label htmlFor={id} style={{verticalAlign: 'bottom'}}>{label}</label>
  </Form.Item>
);

export const FormUpload = ({name, rules, label, valuePropName, action, maxCount = 1, uploadType = 'dragger', beforeUpload, buttonLabel = null, multiple = false, accept = null, extra = null, style = null,removeIcon=false}) => {

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };


  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      valuePropName={valuePropName}
      getValueFromEvent={normFile}
      extra={extra}
      style={style}
    >
      {
        uploadType === 'dragger' ? (
          <Dragger name={name} action={action} accept={accept} maxCount={maxCount} multiple={multiple} beforeUpload={beforeUpload}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <em>Cliquez ou déposez un fichier ici</em>
            <p className="ant-upload-text">{buttonLabel}</p>
          </Dragger>
        ):(
          <Upload name={name} action={action} accept={accept} maxCount={maxCount} multiple={multiple} beforeUpload={beforeUpload}>
            <Button>{ (!removeIcon) && (<UploadOutlined />) } {buttonLabel} </Button>
          </Upload>
        )
      }
    </Form.Item>
  );
};

export const DeleteButton = ({component, onConfirm, onCancel, disabled}) => {
  if(disabled){
    return (
      <>
        {component}
      </>
    );
  }else{
    return (
      <Popconfirm
        title="Êtes-vous sûr de vouloir supprimer cet élément ?"
        onConfirm={onConfirm}
        onCancel={onCancel}
        okText="Confirmer"
        cancelText="Annuler"
      >
        {component}
      </Popconfirm>
    );
  }
};