import React from 'react';
import { all, takeLatest, put, fork, call } from "redux-saga/effects";
import axios from "axios";
import { setToken, clearToken } from '../../helpers/cookies';
import actions from "./actions";
import modalActions from '../modals/actions';
import ApiProvider from "./../../helpers/api";
import apiConfig from "../../config/api.config";
import * as notificationHelper from '../../helpers/notificationHelper';
import history from "../../helpers/history";

export function* registerUser(){
  yield takeLatest(actions.REGISTER_USER, function*({ payload }) {

    try{
      yield put(actions.toggleRegisterLoader(true));

      yield call(axios.post, `${apiConfig.apiUrl}/users/register`, payload);

      notificationHelper.showSuccess(`Bienvenue !`, `Un email vous a été transmis sur votre boite mail. Veuillez cliquer sur le lien afin de confirmer votre inscription. (N'oubliez pas de vérifier vos spams en cas de non réception)`);
      yield put(modalActions.hideModal('homeSignup'));

      yield put(actions.toggleRegisterLoader(false));

    }catch (e){

      yield put(actions.toggleRegisterLoader(false));

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* fetchData() {

  yield takeLatest(actions.FETCH_USER, function*() {
    try {

      yield put(actions.toggleUserLoader(true));

      const response = yield call(ApiProvider.axiosWithToken.get, "/users/get-profile");

      yield put(actions.updateUser(response.data.user));

      const { user } = response.data;

      if(!(user.companyName
        && user.companyAddress
        && user.companyZip
        && user.companyCity
        && user.firstname
        && user.lastname)
      ){
        yield put(modalActions.showModal('customerNewProfile'));
      }else{
        yield put(modalActions.hideModal('customerNewProfile'));
      }

      yield put(actions.toggleUserLoader(false));

    } catch (error) {

      yield put(actions.toggleUserLoader(false));

      if(error.response.data.error){
        notificationHelper.showError(error.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* confirmUser(){
  yield takeLatest(actions.CONFIRM_USER, function*({ payload }) {

    try{

      const response = yield call(axios.post, `${apiConfig.apiUrl}/users/confirm`, {
        hash: payload.hash
      });

      notificationHelper.showSuccess(`Confirmation`, `Votre email a bien été vérifié, veuillez compléter votre profil pour continuer`, 5);
      yield put(actions.logUser(response.data.token));

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
        history.push('/');
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* initialUpdateUser(){
  yield takeLatest(actions.REQUEST_INITIAL_UPDATE_USER, function*({ payload }) {

    try{

      yield call(ApiProvider.axiosWithToken.put, `/users`, payload);

      yield put(actions.fetchUser());
      notificationHelper.showSuccess(`Succès`, `Vos informations ont bien été prises en compte`, 5);

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* updateUser(){
  yield takeLatest(actions.REQUEST_UPDATE_USER, function*({ payload }) {

    try{

      yield call(ApiProvider.axiosWithToken.put, `/users/update`, payload);

      yield put(actions.fetchUser());
      notificationHelper.showSuccess(`Succès`, `Vos informations ont bien été prises en compte`, 5);

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* logUser(){
  yield takeLatest(actions.LOG_USER, function*({ payload }) {

    try{

      const { token } = payload;

      setToken(token);

      ApiProvider.setToken(token);

      yield put(actions.fetchUser());

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* logout() {
  yield takeLatest(actions.LOGOUT, function*() {
    clearToken();
    history.push('/');
  });
}

export function* requestLogin(){
  yield takeLatest(actions.REQUEST_LOGIN, function*({ payload }) {

    try{

      const { email, password } = payload;

      const response = yield call(axios.post, `${apiConfig.apiUrl}/users/authenticate`, {
        email,
        password
      });

      notificationHelper.showSuccess(`Bonjour !`, `Quel plaisir de vous revoir !`, 5);
      yield put(actions.logUser(response.data.token));

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
        history.push('/');
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* requestRecoverPwd(){
  yield takeLatest(actions.REQUEST_RECOVER_PWD, function*({ payload }) {

    try{

      const { email } = payload;

      const response = yield call(axios.post, `${apiConfig.apiUrl}/users/recover-pwd`, {
        email
      });

      notificationHelper.showSuccess(`C'est noté !`, `Un email avec un nouveau mot de passe vous a été transmis`, 5);
      yield put(modalActions.hideModal('homeRecoverPwd'));

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
        history.push('/');
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}

export function* fetchCompanyInfo(){
  yield takeLatest(actions.REQ_FETCH_COMPANY_INFO, function*({ payload }) {

    try{
      const baseURL =  `https://api.insee.fr/entreprises/sirene/V3.11`;
      const config = {
        headers: {
          Authorization: `Bearer ${apiConfig.sireneApiToken}`
        }
      };

      // const response = yield call(axios.get,`${apiConfig.apiUrl}/companiesInformation/${payload}`);
      const response = yield call(axios.get, `${baseURL}/siren/${payload}`, config);

      const {uniteLegale} = response.data;

      if(uniteLegale && uniteLegale.periodesUniteLegale && Array.isArray(uniteLegale.periodesUniteLegale) && uniteLegale.periodesUniteLegale[0]){

        const siret = `${payload}${uniteLegale.periodesUniteLegale[0].nicSiegeUniteLegale}`;

        const {denominationUniteLegale} = uniteLegale.periodesUniteLegale[0];

        const response = yield call(axios.get, `${baseURL}/siret/${siret}`, config);

        const {etablissement} = response.data;

        if(etablissement){

          const {
            numeroVoieEtablissement,
            typeVoieEtablissement,
            libelleVoieEtablissement,
            complementAdresseEtablissement,
            codePostalEtablissement,
            libelleCommuneEtablissement
          } = etablissement.adresseEtablissement;

          const address = `${numeroVoieEtablissement ? numeroVoieEtablissement : ''} ${typeVoieEtablissement ? typeVoieEtablissement : '' } ${libelleVoieEtablissement ? libelleVoieEtablissement : ''}${complementAdresseEtablissement ? ` - ${complementAdresseEtablissement}` : ''}`;

          const {
            numeroVoie2Etablissement,
            typeVoie2Etablissement,
            libelleVoie2Etablissement,
            complementAdresse2Etablissement,
          } = etablissement.adresse2Etablissement;

          const address2 =  `${numeroVoie2Etablissement ? numeroVoie2Etablissement : ''} ${typeVoie2Etablissement ? typeVoie2Etablissement : '' } ${libelleVoie2Etablissement ? libelleVoie2Etablissement : ''}${complementAdresse2Etablissement ? ` - ${complementAdresse2Etablissement}` : ''}`;

          yield put(actions.updateCompanyInfo({
            companyName: denominationUniteLegale,
            companyAddress: address.substring(0,44).trimStart(),
            companyAddress2 : address2.substring(0,44).trim(),
            companyZip: codePostalEtablissement,
            companyCity: libelleCommuneEtablissement
          }))

        }

      }else{
        yield put(actions.updateCompanyInfo({
          companyName: null,
          companyAddress: null,
          companyZip: null,
          companyCity: null
        }));
        notificationHelper.showError('NO_COMPANY_FOUND');
      }
    }catch (e){
      yield put(actions.updateCompanyInfo({
        companyName: null,
        companyAddress: null,
        companyZip: null,
        companyCity: null
      }));
      notificationHelper.showError('NO_COMPANY_FOUND');
    }
  });
}

export function* requestUpdateOptions(){
  yield takeLatest(actions.REQUEST_UPDATE_OPTIONS, function*({ payload }) {

    try{

      const {data} = payload;

      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if(Array.isArray(data[key])){
          for(let i = 0; i < data[key].length; i++){
            formData.append(key, data[key][i].originFileObj);
          }
        }else{
          formData.append(key, data[key]);
        }
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      yield call(ApiProvider.axiosWithToken.put,
        "/users/options",
        formData,
        config);


      yield put(actions.fetchUser());
      notificationHelper.showSuccess(`Succès`, `Vos informations ont bien été prises en compte`, 5);

    }catch (e){

      if(e.response.data.error){
        notificationHelper.showError(e.response.data.error);
      }else {
        notificationHelper.showError('SERVER_ISSUE');
      }
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(fetchData),
    fork(registerUser),
    fork(confirmUser),
    fork(logUser),
    fork(logout),
    fork(initialUpdateUser),
    fork(updateUser),
    fork(requestLogin),
    fork(fetchCompanyInfo),
    fork(requestRecoverPwd),
    fork(requestUpdateOptions)
  ])
}
