import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {Row, Col, Form, Spin, Button} from 'antd';
import connect from "react-redux/es/connect/connect";

import userActions from '../../../Redux/user/actions';
import {FormInput, FormSelect, FormCheckbox} from '../../../helpers/forms';
import * as notificationHelper from "../../../helpers/notificationHelper";
import pays from '../../../resources/pays.json';

class TabCoordinates extends Component{

  state = {
    sameInvoicingCoordinates: false
  };

  formRef = React.createRef();

  onChange = (e) => {
    this.setState({sameInvoicingCoordinates: e.target.checked});
  };

  onFinish = (values) => {
    const {
      billingAddress,
      billingCity,
      billingName,
      billingRegion,
      billingZip,
      sameInvoicingCoordinates
  } = values;

    if(!sameInvoicingCoordinates){
      if(!(billingAddress && billingCity && billingName && billingRegion && billingCity && billingZip)){
        notificationHelper.showError('MISSING_INPUT');
      }else{
        this.props.requestUpdateUser(values);
      }
    }else{
      this.props.requestUpdateUser(values);
    }
  };

  componentDidMount(){
    if(this.props.user) {
      this.setState({
        sameInvoicingCoordinates: this.props.user.sameInvoicingCoordinates
      });
    }
  }

  render(){
    const { user } = this.props;

    if(!user) return (<></>);

    const regions = [
      {text: 'France métropolitaine / TVA 20%', value: 'France métropolitaine'},
      {text: 'Martinique - Guadeloupe - Réunion / TVA 8,5 %', value: 'Martinique - Guadeloupe - Réunion'},
      {text: 'UE - Hors France / TVA 0%', value: 'UE - Hors France'},
      {text: 'Hors UE / TVA 0%', value: 'Hors UE'},
    ];

    return (
      <Spin spinning={!user}>
        <Form onFinish={this.onFinish} ref={this.formRef} initialValues={{
          country : pays.find((item)=>item.value === (user.companyCountry ? user.companyCountry : 'FR')).value
        }}>
          <div className='grey-block'>
            <h3>
              Mes coordonnées professionnelles
              <Button
                  type='default'
                  style={{marginLeft: 20}}
                  size='small' className='blue-btn' htmlType='submit'>
                Enregistrer
              </Button>
              <Button
                  type='default'
                  style={{marginLeft: 10}}
                  size='small'
                  onClick={() => this.formRef.current.resetFields()}
              >
                Annuler
              </Button>
            </h3>
            <div style={{marginTop: -10, marginBottom: 20}}>
              <em>Coordonnées connues de vos clients et que nous utiliserons pour les relancer</em>
            </div>
            <Row gutter={16}>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.companyName : ''} type='text' placeholder='Raison sociale' label='Raison sociale' name='companyName' rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.companyRcs : ''} type='text' placeholder='SIREN' label='SIREN' name='companyRcs'/></Col>
              <Col span={10} offset={1}><FormSelect placeholder='Zone géographique' label='Zone géographique' name='region' options={regions} initialValue={user ? user.region : 'France métropolitaine'} rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.companyVatNumber : ''} type='text' placeholder='TVA intra communautaire' label='TVA intra communautaire' name='companyVatNumber'/></Col>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.lastname : ''} type='text' placeholder='Nom' label='Nom' name='lastname' rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.firstname : ''} type='text' placeholder='Prénom' label='Prénom' name='firstname' rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.companyAddress : ''} type='text' placeholder='N° et libellé de la voie' label='N° et libellé de la voie' name='companyAddress' maxLength={44} rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.companyZip : ''} type='text' placeholder='Code postal' label='Code postal' name='companyZip' rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.companyAddress2 : ''} type='text' placeholder={'Complément d\'adresse :'} label={'Complément d\'adresse :'} name='companyAddress2' maxLength={44}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.companyCity : ''} type='text' placeholder='Localité' label='Localité' name='companyCity' rules={[{required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.companyPhone : ''} type='text' placeholder='Téléphone fixe / mobile' label='Téléphone fixe / mobile' name='companyPhone'/></Col>
              <Col span={10} offset={2}>
              <FormSelect
                  name={'country'}
                  rules={[
                    {required : true}
                  ]}
                  showSearch={true}
                  placeholder= {"Choisissez un pays..."}
                  label={'Pays'}
                  options={pays}
                  defaultValue={pays.find((item)=>item.value === (user.companyCountry ? user.companyCountry : 'FR'))}
              />
              </Col>
            </Row>
          </div>
          <div className='grey-block'>
            <h3>
              Mes coordonnées de facturation
              <Button
                type='default'
                style={{marginLeft: 20}}
                size='small' className='blue-btn' htmlType='submit'>
                Enregistrer
              </Button>
              <Button
                  type='default'
                  style={{marginLeft: 10}}
                  size='small'
                  onClick={() => this.formRef.current.resetFields()}
              >
                Annuler
              </Button>
            </h3>
            <div style={{marginTop: -10, marginBottom: 20}}>
              <em>Coordonnées utilisées pour vous adresser nos factures (abonnements et prestations)</em>
            </div>
            <FormCheckbox
              name='sameInvoicingCoordinates'
              initialValue={user ? user.sameInvoicingCoordinates : ''}
              label={`Utiliser mes coordonnées professionnelles comme coordonnées de facturation`}
              id='sameInvoicingCoordinates'
              onChange={this.onChange}
            />

            <Row gutter={16} style={this.state.sameInvoicingCoordinates ? {display: 'none'} : {marginTop: 20}}>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.billingName : ''} type='text' placeholder='Raison sociale' label='Raison sociale' name='billingName' rules={[{warningOnly: true, required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.billingAddress : ''} maxLength={44} type='text' placeholder='N° et libellé de la voie' label='N° et libellé de la voie' name='billingAddress' rules={[{warningOnly: true, required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={1}><FormInput initialValue={user ? user.billingZip : ''} type='text' placeholder='Code postal' label='Code postal' name='billingZip' rules={[{warningOnly: true, required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.billingCity : ''} type='text' placeholder='Localité' label='Localité' name='billingCity' rules={[{warningOnly: true, required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={1}><FormSelect placeholder='Zone géographique' label='Zone géographique' name='billingRegion' options={regions} initialValue={user ? user.billingRegion : 'France métropolitaine'} rules={[{warningOnly: true, required: true, message:'Ce champ est obligatoire'}]}/></Col>
              <Col span={10} offset={2}><FormInput initialValue={user ? user.billingVatNumber : ''} type='text' placeholder='TVA intra communautaire' label='TVA intra communautaire' name='billingVatNumber'/></Col>
            </Row>
          </div>
        </Form>
      </Spin>
    )
  }
}


export default withRouter(connect(
  state => ({
    user: state.user.info
  }),
  {
    requestUpdateUser: userActions.requestUpdateUser
  }
)(TabCoordinates));